import {generateID} from './id';

export const setCookie = (name,value,days) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  if (typeof document !== `undefined`) {
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
}

export const getCookie = (name) => {
  if (typeof document !== `undefined`) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
  }
  return null;
}

export const eraseCookie = (name) => {
  if (typeof document !== `undefined`) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}

export const getOrCreatePID = () => {
  let existingPID = getCookie('pid');
  if(existingPID) {
    return existingPID
  } else {
    const newPID = 'p'+generateID()
    setCookie('pid',newPID,999)
    return newPID
  }
}
